import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import NotaryPageTemplate from '../components/NotaryPageTemplate'
import Layout from '../components/Layout'

const NotaryPage = () => {
//  const { frontmatter } = data.markdownRemark

  return (
    <Layout includePreFooter={true}>
      <NotaryPageTemplate
//        title={frontmatter.title}
//        meta_title={frontmatter.meta_title}
//        meta_description={frontmatter.meta_description}
//        heading={frontmatter.heading}
//        description={frontmatter.description}
//        features={frontmatter.features}
//        carousel={frontmatter.carousel}
//        usecases={frontmatter.usecases}
//        datafeed={frontmatter.datafeed}
//        consumerfeatures={frontmatter.consumerfeatures}
//        consumerfeaturesimg={frontmatter.consumerfeaturesimg}
//        enterprisefeatures={frontmatter.enterprisefeatures}
//        cta={frontmatter.cta}
      />
    </Layout>
  )
}

//NotaryPage.propTypes = {
//  data: PropTypes.shape({
//    markdownRemark: PropTypes.shape({
//      frontmatter: PropTypes.object,
//    }),
//  }),
//}

export default NotaryPage
