// NOTE: check TODO's when additional content, demos, etc are ready.
// Blocktag Notary Homepage

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import config from '../../../config'

import { ContactForm } from '../forms/ContactForm'
import { InquiryForm } from '../forms/ContactForm/inquiry'
import Carousel from '../Carousel'
import Showcase from '../Showcase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLink, faFileInvoiceDollar, faShieldAlt, faChartLine, faCheckCircle, faArrowCircleRight, faChevronLeft, faChevronRight, faBackward, faMobileAlt, faHammer, faWifi, faKey, faWallet } from '@fortawesome/free-solid-svg-icons'

import handleClick from '../../handleClick.js'
import routeChange from '../../routeChange.js'

import { OutboundLink } from "gatsby-plugin-google-gtag"


library.add(faLink, faFileInvoiceDollar, faShieldAlt, faChartLine, faCheckCircle, faArrowCircleRight, faChevronLeft, faChevronRight, faBackward, faMobileAlt, faHammer, faWifi, faKey, faWallet)

const NotaryPageTemplate = ({
  heading,
  description,
  meta_title,
  meta_description,
  usecases,
  carousel,
//  datafeed,
  consumerfeatures,
//  consumerfeaturesimg,
  enterprisefeatures,
  features,
  cta,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <div
      className='flex flex-col font-sans bg-indigo-800 bg-top bg-no-repeat bg-cover'
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 30%, rgba(0,0,0,1) 90%), url("/img/notary/multi-party-notarisation.png")`, marginTop: '-80px' }}>
      <div className='container px-8 mx-auto'>
        {/* hero */}
        <main className='flex flex-wrap items-center pt-48 text-center text-white lg:px-24'>
          <div className='w-full whitespace-pre-line md:w-full'>
            <h1 className='text-4xl font-light tracking-tight lg:text-6xl'>
              Remote Stamp Notarisation
            </h1>
            <h4 className='my-4 text-base font-light text-gray-400'>
               <ul>
                 <li>Notary Public: Find new remote stamp notarisation clients</li>
                 <li>Clients: Simplify notarisation with printed notary stamp</li>
                 <li>Organisations: Cost efficient doc authentication with phone camera </li>
               </ul>
            </h4>
            <InquiryForm />
          </div>
        </main>

        <hr className='border-gray-700' />

        {/* subhero section */}
        <div className='flex flex-wrap pb-12 mt-8 overflow-hidden text-center text-gray-500'>
       {/*{usecases.map(u => (
            <div key={`feature_${u.heading}`} className='w-full py-4 whitespace-pre-line sm:w-1/2 lg:w-1/4'>
              <FontAwesomeIcon icon={u.icon} className='mb-4 text-4xl text-teal-500' />
              <h2 className='pb-2 text-lg text-gray-100 font-base'>
                {u.heading}
              </h2>
              <h3 className='text-base font-light'>
                {u.description}
              </h3>
            </div>
          ))} */}
       
        </div>
       
       {/* App download section */}
       {/*<a href="https://apps.apple.com/us/app/blocktag/id1478833776">
           <img
                className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                style={{ width: `200px` }}/>
           <br />
       </a> */}
       
       
       <a onClick={() => {
               handleClick("DownloadAppFromWebNotary");
               routeChange('https://apps.apple.com/us/app/blocktag/id1478833776');
            }}>
           <img
                className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                style={{ width: `200px` }}/>
           <br />

       </a>

      </div>
    </div>

    {/* first section: tag types carousel */}
    <div
      id='products'
      className='flex flex-col bg-white'
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,1) 50%, #f2f2f2 100%), url("/img/earth.jpg")` }}>
      <div className='container px-8 mx-auto'>
       {/*<Carousel data={carousel} /> */}
      </div>
    </div>
       
   
    {/* Section: Example Use Cases */}

   <div className='flex flex-col bg-white'>  {/* Contains html elements to a smaller column area */}
     <div className='container px-8 mx-auto'>
       
       <div className='flex flex-wrap items-center my-12 overflow-hidden text-left'>
         <div className='w-full text-3xl text-center'>Solution</div>
       {/*<h3 className='w-full text-center'> Users can upload authenticated tag data by authenticating tag to identify themselves as tag data owners/stakeholders</h3>*/}
       <Showcase data={[
           {
             img: '/img/notary/remote-stamp-notarisation.jpeg',
             title: 'Print Notary Stamp With Printer',
             info: ['Instead of in-person physical stamp,', ' print unclonable stamp with home or office printer and notarize remotely'],
             origin: null,
             certs: null,
             eventfeed: null
           },
           {
             img: '/img/samples/BlocktagOriginalPaper.jpg',
             title: 'Simplified Notarisation Process',
             info: ['Printed stamp is paper doc’s fingerprint. Similar to physical stamp, seal, signature.', 'Replace in person notarisation.'],
             origin: null,
             certs: null,
             eventfeed: null
           },
           {
             img: '/img/samples/BlocktagOriginalPaper.jpg',
             title: 'Verify Notarized Docs and Identities With Phone Camera',
             info: ['Organization requesting notarization can scan printed stamp on doc to verify original doc and identities', 'of client, notary and other parties.'],
             origin: null,
             certs: null,
             eventfeed: null
           }
         ]} />
       </div>

     </div>
       
       
       <div className='w-full text-center py-2 my-4'>
         <a onClick={() => {
             handleClick("DownloadTagBody");
             routeChange('https://generate.blocktag.com');

         }}
         class="flex-grow inline-block px-8 py-2 my-4 mr-4 text-sm text-white bg-teal-500 border border-teal-500 rounded sm:my-0 hover:bg-teal-700 hover:border-teal-700">Download Tags Now</a>
       </div>
       
   </div>
       

    <div className='flex flex-col bg-dark mb-8'>  {/* Contains html elements to a smaller column area */}
      <div className='container px-8 mx-auto'>
       
       
       {/* Section: How It Works*/}
       <div className='flex flex-wrap items-center overflow-hidden text-left'>
          <div className='w-full mb-8 text-3xl font-light text-center text-black-100'>How It Works</div>

         {/* Left */}
         {/* Unclonable, QR-like digital tag design uses special color pattern encodings */}
         <div className='w-full sm:w-1/3 sm:order-1 pl-2 pr-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">1.Notary Uploads Stamp Image </h2>
            <h3>Notary Public registers on Blocktag website and uploads image of their notary stamp.</h3>
       <img className='mx-auto pt-2 pl-2 pr-2' style={{padding: 40}} src="/img/notary/physical-notary-stamp.png" />
         </div>

         {/* Center */}
         {/* Printing amplifies unique, unclonable physical characteristics */}
         <div className='w-full sm:w-1/3 sm:order-2  pl-2 pr-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">2.Client Downloads Stamp QR </h2>
            <h3> Client searches for a Blocktag registered Notary Public. Downloads notary’s stamp as secure QR </h3>
       <img className='mx-auto pt-2 pl-2 pr-2' style={{padding: 0}} src="/img/notary/virtual-notary-stamp.png" />
         </div>

         {/* Right */}
         {/* When a thing is tagged, it has its own fingerprint and record */}
         <div className='w-full sm:w-1/3 sm:order-3  pl-2 pr-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">3.Client Prints Stamp QR</h2>
            <h3>Client prints stamp QR on notarisation docs using home printer.</h3>
       <img className='mx-auto pt-2 pl-2 pr-2'  style={{padding: 40}} src="/img/notary/inkjet-printer.jpeg" />
         </div>
       
       
       </div>
       
       <div className='flex flex-wrap items-center overflow-hidden text-left'>

           {/* Row2 Left */}
           <div className='w-full sm:w-1/3 sm:order-1 pl-2 pr-2'>
              <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">4.Client Fingerprints Stamp</h2>
              <h3>During video call with Notary, Client use phone camera to capture photo of printed stamp as fingerprint.</h3>
       <img className='mx-auto pt-2 pl-2 pr-2' style={{padding: 40}} src="/img/notary/notary-stamp-fingerprint.png" />
           </div>

           {/* Row2 Center */}
           <div className='w-full sm:w-1/3 sm:order-2  pl-2 pr-2'>
              <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">5.Notary Activates Stamp </h2>
              <h3> Blocktag app informs Notary that his stamp is printed by Client. Notary must approve to activate stamp QR. </h3>
       <img className='mx-auto pt-2 pl-2 pr-2' style={{padding: 40}} src="/img/notary/notary-public-remote-meeting.png" />
           </div>

           {/* Row2 Right */}
           <div className='w-full sm:w-1/3 sm:order-3  pl-2 pr-2'>
              <h2 class="pb-2 text-lg text-black-100 font-base text-center font-weight-bold">6.Organisation Authenticates Stamp</h2>
              <h3>Organization uses Blocktag app to authenticate stamp on docs mailed by Client. Client’s identity can be verified with camera Face ID.</h3>
       <img className='mx-auto pt-2 pl-2 pr-2'  style={{padding: 40}} src="/img/notary/notary-authentication-success.png" />
           </div>
     
       </div>
       
       
       {/* Section: Mobile Authentication*/}
       
       
       {/*
       <div className='flex flex-wrap items-center pt-12 mt-12 overflow-hidden text-left'>
       
          <div className='w-full mb-16 text-3xl font-light text-center text-black-100'>Mobile Authentication</div>


         <div className='w-full sm:w-1/3 sm:order-1  pl-2 pr-2 pt-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center"> Authentic Tagged Product</h2>
            <h3> Scan fingerprinted tag with Blocktag app to detect original from cloned tags. No special scan hardware required.</h3>
            <img className='mx-auto' src="/img/mobile_ss/AuthenticateSuccessfulCrop.jpeg" />
         </div>

       
         <div className='w-full sm:w-1/4 sm:order-2 pl-2 pr-2 pt-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center"> Genuine User Identification </h2>
            <h3> Challenge user to identify themselves using Blocktag app with Username, Email, FaceID or CryptoID.</h3>
            <img className='mx-auto' src="/img/mobile_ss/SupplierAsUserCrop.jpeg" />
         </div>
         
       
       <div className='w-full sm:w-1/3 sm:order-2  pl-2 pr-2 pt-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center"> Proof Of User Presence/Possession </h2>
            <h3> Prove user is physically close or has physical control of an authentic tag instead of a tag screenshot. Challenge user to identify themselves using Blocktag app with Username, Email, FaceID or CryptoID.</h3>
            <img className='mx-auto' src="/img/mobile_ss/AuthenticateDetectScreenCrop2.jpeg" />
         </div>
        
              
         <div className='w-full sm:w-1/3 sm:order-3 pl-2 pr-2 pt-2'>
            <h2 class="pb-2 text-lg text-black-100 font-base text-center"> QR Backwards Compatible</h2>
            <h3> Users can read tag data using any native camera reader without installing Blocktag app and skip authentication.</h3>
            <img className='mx-auto' src="/img/mobile_ss/BlocktagCameraCrop.jpeg" />
         </div>
        
       
       </div>
       
       */}
       
      </div>
    </div>
       
       
       {/* third section: consumer simplicity */}

       {/*
    <div className='flex flex-col bg-blue-900'>
      <div className='container px-8 mx-auto'>

        <div className='flex flex-wrap items-center pt-12 mt-12 overflow-hidden text-left'>
          <div className='w-full mb-16 text-3xl font-light text-center text-gray-100'>Consumer Simplicity</div>

         <div className='w-full sm:w-1/3 sm:order-2'>
            <img className='mx-auto' src={consumerfeaturesimg} />
          </div>
         

       
       
          <div className='w-full px-12 text-center text-gray-100 sm:pb-8 lg:pl-24 sm:pl-0 md:pr-12 sm:pr-4 sm:text-right sm:w-1/3 sm:order-1'>
       {consumerfeatures.slice(0, consumerfeatures.length / 2).map((f, i) => (
              <div key={`consumer_${i}`} className='h-32 my-8 sm:h-40'>
                <FontAwesomeIcon icon={f.icon} className='mb-4 text-3xl text-teal-500' />
                <h3 className='text-base font-base'>{f.heading}</h3>
                <p className='text-sm'>{f.description}</p>
              </div>
            ))}
       
          </div>

          <div className='w-full px-12 pb-8 text-center text-gray-100 lg:pr-24 sm:pr-0 md:pl-12 sm:pl-4 sm:text-left sm:w-1/3 sm:order-3'>
       
         {consumerfeatures.slice(consumerfeatures.length / 2, consumerfeatures.length).map((f, i) => (
              <div key={`consumer_${3 + i}`} className='h-32 my-8 sm:h-40'>
                <FontAwesomeIcon icon={f.icon} className='mb-4 text-3xl text-teal-500' />
                <h3 className='text-base font-base'>{f.heading}</h3>
                <p className='text-sm'>{f.description}</p>
              </div>
            ))}
       
          </div>
        </div>
      </div>
    </div>
       */}
       
       
    {/*
    <div className='flex flex-col bg-white'>
      <div className='container px-8 mx-auto'>

        <div className='flex flex-wrap items-center pt-16 pb-4 overflow-hidden text-left md:py-12 md:my-12'>
          <div className='w-full mb-16 text-3xl font-light text-center text-gray-800'>Enterprise Efficiency</div>
         
          <div className='w-full pb-12 md:w-1/2 md:pb-0'>
            <img className='max-w-sm mx-auto md:max-w-full md:mx-0' src='/img/laptop.png' />
          </div>
         
          <div className='w-full px-6 sm:px-20 md:pr-0 md:w-1/2'>
            <ul>
              {enterprisefeatures.map((f, i) => (
                <li key={`enterprise_${i}`} className='mb-12'>
                  <h3 className='text-lg font-medium leading-loose'>{f.heading}</h3>
                  <p className='text-base font-light'>{f.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
       
    */}
       
   {/* Section: Our Partners*/}
       {/*
   <div className='flex flex-wrap items-center overflow-hidden text-left'>
      <div className='w-full mb-8 text-3xl font-light text-center text-black-100'>Our Partners</div>
       
       <div className='w-full sm:w-1/3 sm:order-1 pl-2 pr-2'></div>
       
       <div className='w-full sm:w-1/3 sm:order-2  pl-2 pr-2'>
        <img className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/berkeley-multi-color-on-blue.png" />
       </div>
       
       <div className='w-full sm:w-1/3 sm:order-3  pl-2 pr-2'></div>
   </div>
       */}
       
       {/* fifth section: features */}
       

    <div className='flex flex-col bg-gray-100'>
      <div className='container px-8 mx-auto'>

        <div className='flex flex-wrap items-start py-12 my-12 overflow-hidden text-left'>
          <div className='w-full mb-12 text-3xl font-light text-center'>Our Partners</div>
       
       {/*
          {features.map((f, i) => (
            <div key={`feature_${i}`} className='flex w-full p-4 text-gray-800 md:w-1/2 lg:w-1/4'>
              <FontAwesomeIcon icon={faCheckCircle} className='flex-shrink mx-4 text-3xl text-teal-500' />
              <div className='flex-grow'>
                <h3 className='text-base font-base'>{f.heading}</h3>
                <p className='text-sm font-light'>{f.description}</p>
              </div>
            </div>
          ))}
         
         */}
         
       
       {/* Microsoft */}
       <div className='w-full sm:w-1/3 sm:order-1 pl-2 pr-2'> {/* sm:w-1/3 */}
        <img className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/MS_Startups_Celebration_Badge_Dark.png" />
       </div>
       
       {/* Berkeley */}
       <div className='w-full sm:w-1/3 sm:order-2  pl-2 pr-2'> {/* sm:w-1/3 */}
        <img className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/berkeley-multi-color-on-blue.png" />
       </div>

       {/* Nvidia */}
       <div className='w-full sm:w-1/3 sm:order-3 pl-2 pr-2'> {/* sm:w-1/3 */}
        <img className='mx-auto pt-2 pl-2 pr-2' src="/img/partners/nvidia-inception-program-badge-rgb-for-screen.png" />
       </div>

       
        </div>
      </div>
    </div>
         


    {/* TODO: Uncomment once demos are ready for enterprise and consumer apps
    <div className='flex flex-col bg-white'>
      <div className='container px-8 mx-auto'>

        */}{/* cta */}{/*
        <div className='flex flex-wrap items-center overflow-hidden text-center'>
          {cta.map((c, i) => (
            <div key={c.heading} className='w-1/2 px-32 py-24 transition ease-out duration-700 transform hover:scale-105'>
              (screenshot){c.screenshot}
              <br />
              <br />
              <br />
              <h2 className='text-xl'>{c.heading}</h2>
              <p className='my-4 text-sm'>{c.description}</p>
              <a href={c.link}>
                <button className='flex-shrink-0 px-8 py-1 my-4 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded hover:bg-teal-700 hover:border-teal-700' type='button'>
                  {c.linklabel}
                  <FontAwesomeIcon icon={faArrowCircleRight} className='ml-2' />
                </button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
		*/}

    {/* get in touch */}
    <div className='flex flex-col text-white bg-blue-900'>
      <div className='container px-8 mx-auto'>
        <div className='w-full my-6 mt-24 text-4xl font-light text-center'>Get in Touch</div>
        <div className='w-full my-6 text-xl font-light text-center whitespace-pre-line'>
          <a href={`mailto:${config.email}`}>{config.email}</a>{`\n`}
          <a href={`tel:+${config.phone.replace(/\D/g, '')}`}>{config.phone}</a>
        </div>
        <div className='flex flex-wrap items-stretch py-12 my-12 overflow-hidden text-left'>
          <div className='flex flex-1 w-full md:px-8 sm:w-1/2'>
            <iframe className='w-full h-full border-none' frameBorder='0' src={`https://www.google.com/maps/embed/v1/place?q=place_id:${config.mapPlaceId}&key=${config.googleCloudApiKey}`} allowFullScreen />
          </div>
          <div className='w-full px-8 sm:w-1/2'>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
)

//NotaryPageTemplate.propTypes = {
//  meta_title: PropTypes.string,
//  meta_description: PropTypes.string,
//  heading: PropTypes.string,
//  description: PropTypes.string,
//  usecases: PropTypes.array,
//  features: PropTypes.array,
//  carousel: PropTypes.array,
//  datafeed: PropTypes.array,
//  consumerfeatures: PropTypes.array,
//  consumerfeaturesimg: PropTypes.string,
//  enterprisefeatures: PropTypes.array,
//  cta: PropTypes.array,
//}

export default NotaryPageTemplate
