import React from 'react'
import { Formik, Field } from 'formik'
import validationSchema from './validationInquirySchema'
import handleClick from '../../../handleClick.js'

const InquiryForm = () => {
  return (
    <Formik
      initialValues={{ name: '', email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        window.location.href = `mailto:hello@blocktag.com?subject=Inquiry from ${values.name} (${values.email})&body=I am interested in learning more about Blocktag.`
        setSubmitting(false)
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (

        <form
          onSubmit={ () => {
              handleClick("InquiryHome");
              handleSubmit;
          }}
          onReset={handleReset}
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          className='w-full my-24'>
          <div className='flex flex-wrap items-center py-2 border-teal-500 sm:border-b-2 sm:border-b sm:mx-24 sm:flex-no-wrap'>
            <Field className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-none focus:outline-none' type='text' placeholder='Full Name' name='name' aria-label='Full name' />
            <Field className='w-full px-2 py-1 my-1 mr-3 text-gray-200 placeholder-teal-200 bg-transparent border-b border-b-2 border-teal-500 appearance-none sm:my-0 sm:leading-tight sm:border-none focus:outline-none' type='text' placeholder='Email Address' name='email' aria-label='Email Address' />
            <button className='flex-grow px-8 py-1 my-4 text-sm text-white bg-teal-500 border-4 border-teal-500 rounded sm:my-0 hover:bg-teal-700 hover:border-teal-700' type='submit' disabled={isSubmitting}>
              Inquire
            </button>
          </div>
        </form>
      )}
    />
  )
}

export { InquiryForm }
